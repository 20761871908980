import SubscriptionModal from '@/baseline/subscription';
import UpgradeIcon from '@/baseline/subscription/upgradeIcon';
import useTierPermission from '@/baseline/subscription/useTierPermission';
import type { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import { mutateGraphQL, queryGraphQL, TEMP_HARDCODED_ERROR } from '@/data';
import { EstimateWrite } from '@/data/commerce/estimate.graphql';
import { InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { OrderWrite } from '@/data/commerce/order.graphql';
import { LineItemsRead } from '@/data/management/lineItem.graphql';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import HistoryCommerces from '@/modals/historyCommerces';
import RecurringModal from '@/modals/recurring';
import SchedulePaymentsModal from '@/modals/schedulePaymentsModal';
import TextMessage from '@/modals/textMessage';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import CommerceItemsUpdating from '@/pages/dashboard/commerce/invoices/commerceItemsUpdating';
import DeviceLoadingModal from '@/pages/dashboard/settings/devices/deviceLoadingModal';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModal } from '@/providers/modal';
import {
	CommerceType,
	MutationEstimateWriteArgs,
	MutationInvoiceWriteArgs,
	MutationOrderWriteArgs,
	Order,
	QueryLineItemsReadArgs,
} from '@/types/schema';
import {
	AddCircle as AddCircleIcon,
	Archive as ArchiveIcon,
	ChangeCircle as ChangeCircleIcon,
	ChangeCircle as CreateOrderIcon,
	ContentCopy as ContentCopyIcon,
	CopyAll as CopyAllIcon,
	Edit as EditIcon,
	EventRepeat as EventRepeatIcon,
	FindInPage as FindInPageIcon,
	LocalOffer as LocalOfferIcon,
	MoreVert as MoreVertIcon,
	Payment as PaymentIcon,
	PointOfSale as PointOfSaleIcon,
	Share as ShareIcon,
	Sms as SmsIcon,
	SyncProblem as SyncProblemIcon,
} from '@mui/icons-material';
import { type Theme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { isEmpty, toLower, toUpper } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import process from 'process';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CloverDeviceSelectionModal from '../../../settings/devices/cloverDeviceSelectionModal';
import SignAndTagModal from '../../components/signAndTagModal';
import { generateRecurring } from '../orderGQL';
import { updateStock } from '../utils';
import { usePartialOrderTableActions } from './drawerActions';
import MarkStatusActions from './markStatusActions';
import { MoreActions, RecurringActions } from './moreActions';
import makeOrderCopy from './orderUtils';

export const estimateTypes = [ 'estimate', 'proposal', 'quote', 'work_order', 'bid' ];

export default function useOrderActions( order: Order, single?: boolean ) {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const event = useEvents();
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	const editable = usePermissions( permissions.orders.write );
	const payable = usePermissions( permissions.payments.write );
	const { staff } = useUserInfo();
	const orderActions = usePartialOrderTableActions();
	const { company } = useCompany();
	const confirmDialog = useConfirmDialog();
	const subscriptionIsValid = useTierPermission( 'INVOICE_TEXT' );
	
	const { isCloverDevice } = useGetDeviceInfo();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const removeSchedulePayment = order.externalId && order.status === 'PARTIALLY_PAID' && isEmpty( order.scheduledPayments );
	const disableEditIfPaid = order.status === 'PAID' && staff?.disableActions?.commerces?.disableEditPaid;
	
	useEffect( () => {
		if ( !single ) return;
		if ( editable && order.standing && !order.standingActive ) {
			showModal( RecurringModal, { maxWidth: 'sm', fullPageModal: true }, {
				formOrder: order,
			} );
		}
	}, [] );
	
	const convertActions = estimateTypes.map( ( type ) => ( {
		name   : `${t( 'common:create' )} ${t( `common:${type}` )}`,
		details: t( `commerce:turn-${type}` ),
		icon   : <CreateOrderIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( `commerce:confirm-${type}-order` ),
				message: t( `commerce:success-${type}` ),
			} );
			if ( !value ) return;
			try {
				await mutateGraphQL<MutationEstimateWriteArgs>( {
					mutation : EstimateWrite,
					variables: {
						id          : order.id,
						customNumber: Boolean( company?.metadata?.customNumber ),
						input       : {
							type     : toUpper( type ) as CommerceType,
							staff    : staff.id,
							createdAt: new Date(),
						},
					},
				} );
				
				await router.push( `/dashboard/commerce/estimates/${order.id}/edit` );
			} catch ( e ) {
				throw e;
			}
		},
	} ) );
	
	return [
		editable && !isCloverDevice && {
			name       : t( 'common:edit' ),
			icon       : <EditIcon/>,
			details    : disableEditIfPaid ? 'You do not have permission to edit this paid order.' : '',
			buttonProps: { disabled: disableEditIfPaid },
			props      : {
				component: PageLinkComponent,
				href     : `/dashboard/commerce/orders/${order.id}/edit`,
			},
		},
		isCloverDevice && order.externalId && order.gateway?.external === 'CLOVER' && order.status !== 'PAID' && order.status !== 'ACCOUNT' && {
			name   : t( 'commerce:edit-on-clover' ),
			icon   : <PaymentIcon/>,
			onClick: () => {
				showModal( DeviceLoadingModal, {
					maxWidth: 'sm',
					onClose : ( event, reason ) => stayOpen( event, reason ),
				}, {
					invoice       : order,
					title         : 'Edit On Clover',
					bodyTitle     : 'Editing in progress.',
					buttonText    : `Edit ${toLower( order.type )}`,
					secondaryTitle: `Edit the ${toLower( order.type )} on Clover`,
				} );
				// await editOnCloverAndMeteredBilling( invoice, staff );
			},
		},
		!single && {
			name : t( 'common:view' ),
			icon : <FindInPageIcon/>,
			props: {
				component: PageLinkComponent,
				href     : `/dashboard/commerce/orders/${order.id}`,
			},
		},
		( editable && order.standing && order.standingData || order.metadata?.originalCommerce ) && {
			name   : t( 'common:view-history' ),
			icon   : <PaymentIcon/>,
			onClick: () => showModal( HistoryCommerces, { variant: 'drawer' }, { data: order } ),
		},
		...orderActions( order ).slice( 1 ),
		order.externalId && {
			name   : t( 'common:sync-clover' ),
			icon   : <SyncProblemIcon/>,
			onClick: async () => {
				try {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
						id         : order.id,
						reverseSync: true,
					} );
					
					enqueueSnackbar( t( 'common:sync-clover-success' ), { variant: 'success' } );
				} catch ( e ) {
					console.error( e );
					throw new Error( TEMP_HARDCODED_ERROR );
				}
			},
		},
		editable && order.standing && !order.standingActive && {
			name       : t( 'common:active-standing' ),
			buttonProps: { variant: 'contained', color: 'primary' },
			icon       : <EventRepeatIcon/>,
			onClick    : () => showModal( RecurringModal, { maxWidth: 'sm', fullPageModal: true }, {
				formOrder: order,
			} ),
		},
		editable && order.standing && order.standingActive && {
			name       : t( 'common:deactive-standing' ),
			buttonProps: { variant: 'contained', color: 'warning' },
			icon       : <EventRepeatIcon/>,
			details    : t( 'commerce:stop-scheduled-orders' ),
			onClick    : async () => await mutateGraphQL<MutationOrderWriteArgs>( {
				mutation : OrderWrite,
				variables: {
					id    : order.id,
					method: 'Deactivated Standing',
					input : { standingActive: !order.standingActive },
				},
			} ),
		},
		editable && order.standing && order.standingData && {
			name   : 'Create New',
			details: 'Create the next recurring order manually',
			icon   : <AddCircleIcon/>,
			onClick: async () => {
				enqueueSnackbar( 'Please wait while the new order being generated...', { variant: 'info' } );
				await generateRecurring( order.id );
				event.emit( 'reload.graphqlQuery', true );
			},
		},
		!isCloverDevice && order.externalId && order.gateway?.external === 'CLOVER' && order.status !== 'PAID' && order.status !== 'ACCOUNT' && {
			name   : 'Pay on Clover',
			icon   : <PointOfSaleIcon/>,
			onClick: () => showModal( CloverDeviceSelectionModal, { maxWidth: 'sm' }, { invoice: order } ),
		},
		editable && payable && !removeSchedulePayment && order.status !== 'CANCELLED' && order.grandTotal !== 0 && order.status !== 'PAID' && !order.standing && {
			name   : t( 'common:schedule-payment' ),
			icon   : <EventRepeatIcon/>,
			onClick: () => showModal( SchedulePaymentsModal, { maxWidth: 'sm', fullPageModal: true }, { id: order.id } ),
		},
		{
			name   : order.status === 'DRAFT' ? t( 'common:send-text' ) : t( 'common:resend-text' ),
			icon   : <SmsIcon/>,
			endNode: !subscriptionIsValid && <UpgradeIcon permission='INVOICE_TEXT'/>,
			onClick: () => {
				if ( !subscriptionIsValid ) return showModal( SubscriptionModal, { variant: 'fullPageDialog' } );
				return showModal( TextMessage, { maxWidth: 'xs' }, {
					order   : order,
					onSubmit: async () => {
						await mutateGraphQL<MutationOrderWriteArgs>( {
							mutation : OrderWrite,
							variables: {
								id    : order.id,
								method: 'Sent via Text',
								input : {
									sent    : true,
									metadata: {
										...order.metadata,
										sentDates: [ ...!isEmpty( order.metadata?.sentDates )
											? [ ...order.metadata.sentDates, new Date() ]
											: [ new Date() ] ],
									},
								},
							},
						} );
						await updateStock( order, staff?.company || order.company, 'manualSendStock', enqueueSnackbar );
					},
				} );
			},
		},
		{
			name   : t( 'common:copy-link' ),
			icon   : <ContentCopyIcon/>,
			onClick: async () => {
				await navigator.clipboard.writeText( `${process.env.NEXT_PUBLIC_SITE_URL}/p/${order.id}/order` );
				enqueueSnackbar( t( 'common:link-copy-success' ), { variant: 'success' } );
			},
		},
		!isCloverDevice && {
			name : t( 'common:client-view' ),
			icon : <FindInPageIcon/>,
			props: {
				component: 'a',
				href     : `/p/${order.id}/order`,
				target   : '_blank',
			},
		},
		navigator.share && {
			name   : t( 'common:share' ),
			icon   : <ShareIcon/>,
			onClick: () => navigator.share( { url: `${process.env.NEXT_PUBLIC_SITE_URL}/p/${order.id}/order` } ),
		},
		editable && {
			name   : t( 'commerce:convert-invoice' ),
			details: t( 'commerce:turn-invoice' ),
			icon   : <CreateOrderIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title  : t( 'commerce:convert-invoice' ),
					message: t( 'commerce:confirm-invoice' ),
				} );
				if ( !value ) return;
				try {
					await mutateGraphQL<MutationInvoiceWriteArgs>( {
						mutation : InvoiceWrite,
						variables: {
							id          : order.id,
							method      : 'Order to Invoice',
							customNumber: Boolean( company?.metadata?.customNumber ),
							input       : {
								type     : 'INVOICE',
								staff    : staff.id,
								createdAt: new Date(),
							},
						},
					} );
					
					await router.push( `/dashboard/commerce/invoices/${order.id}/edit` );
				} catch ( e ) {
					throw e;
				}
			},
		},
		editable && !order.standing && {
			name   : t( 'common:sign-tag' ),
			icon   : <LocalOfferIcon/>,
			onClick: () => showModal( () => <SignAndTagModal data={order}/>, { maxWidth: 'xs' } ),
		},
		editable && {
			name   : t( 'common:make-copy' ),
			icon   : <CopyAllIcon/>,
			onClick: async () => {
				try {
					const newOrderId = await makeOrderCopy( order, company, staff );
					await router.push( `/dashboard/commerce/orders/${newOrderId}/edit` );
				} catch ( e ) {
					throw e;
				}
			},
		},
		{
			name   : order.metadata?.stock ? t( 'common:put-back' ) : t( 'commerce:update-stock' ),
			details: t( 'commerce:confirm-stock' ),
			icon   : <ArchiveIcon/>,
			onClick: async () => {
				const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
					query    : LineItemsRead,
					variables: { options: { filter: { order: order.id } } },
				} );
				if ( isEmpty( lineItemsRead?.items ) ) {
					enqueueSnackbar( t( 'commerce:empty-stock' ), { variant: 'default' } );
					return;
				} else {
					return showModal( CommerceItemsUpdating, {
						maxWidth: 'lg',
						onClose : ( event, reason ) => stayOpen( event, reason ),
					}, { commerce: order } );
				}
			},
		},
		order.externalId && {
			name   : t( 'common:reverse-sync' ),
			details: t( 'common:duplicate-after-sync' ),
			icon   : <SyncProblemIcon/>,
			onClick: async () => {
				try {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
						id         : order.id,
						reverseSync: true,
					} );
					enqueueSnackbar( t( 'commerce:sync-clover-success' ), { variant: 'success' } );
				} catch ( e ) {
					console.error( e );
					throw new Error( TEMP_HARDCODED_ERROR );
				}
			},
		},
		...editable ? isMobile
			? RecurringActions( order, staff, router, t )
			: !isEmpty( RecurringActions( order, staff, router, t ) ) ? [ {
				name       : t( 'commerce:recurring-actions' ),
				icon       : <ChangeCircleIcon/>,
				nestedItems: RecurringActions( order, staff, router, t ).filter( Boolean ),
			} ] : [] : [],
		...editable && order.status !== 'PAID' ? isMobile
			? convertActions
			: [ {
				name       : t( 'commerce:convert-to' ),
				icon       : <MoreVertIcon/>,
				nestedItems: convertActions.filter( Boolean ),
			} ] : [],
		...isMobile
			? MarkStatusActions( order ) || []
			: [ {
				name       : t( 'commerce:change-status' ),
				icon       : <ChangeCircleIcon/>,
				nestedItems: MarkStatusActions( order ).filter( Boolean ),
			} ],
		...isMobile
			? MoreActions( order, single )
			: [ {
				name       : t( 'common:more-action' ),
				icon       : <MoreVertIcon/>,
				nestedItems: MoreActions( order, single ).filter( Boolean ),
			} ],
	] as ActionProps[];
}
