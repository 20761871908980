import { ActionProps } from '@/components/actions';
import { mutateGraphQL } from '@/data';
import { OrderWrite } from '@/data/commerce/order.graphql';
import { PaymentWrite } from '@/data/commerce/payment.graphql';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { markStatusAction } from '@/pages/dashboard/commerce/invoices/actions/markStatusActions';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import { MutationInvoiceWriteArgs, MutationOrderWriteArgs, MutationPaymentWriteArgs, Order } from '@/types/schema';
import {
	AssignmentTurnedInRounded as AssignmentTurnedInRoundedIcon,
	CreditScore as CreditScoreIcon,
	CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
	Email as EmailIcon,
	MoneyOffCsred as MoneyOffCsredIcon,
} from '@mui/icons-material';
import { toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function MarkStatusActions( order: Order ): ActionProps[] {
	const { t } = useTranslation();
	const confirmDialog = useConfirmDialog();
	const payable = usePermissions( permissions.payments.read || permissions.payments.write );
	const editable = usePermissions( permissions.orders.write );
	const router = useRouter();
	const hasPayments = order.paidTotal > 0 && order.payments?.find( ( payment ) => payment?.status === 'PAID' && payment?.refundedAmount <= 0 );
	const { enqueueSnackbar } = useSnackbar();
	const canBeUnSynced = order.externalId && ![ 'PAID', 'PARTIALLY_PAID' ].includes( order.status );
	
	return [ hasPayments && {
		name   : t( 'commerce:issue-refund' ),
		icon   : <CurrencyExchangeRoundedIcon/>,
		details: 'Scroll down to payments',
		onClick: async () => await router.push( `/dashboard/commerce/orders/${order.id}` ),
	}, order.status === 'DRAFT' && !order.standing && {
		name   : t( 'common:mark-sent' ),
		details: order.standing
			? t( 'commerce:recurring-sent' )
			: t( 'commerce:assign-without-send' ),
		icon   : <EmailIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( 'common:mark-sent' ),
				message: t( 'commerce:confirmation-send' ),
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationOrderWriteArgs>( {
				mutation : OrderWrite,
				variables: {
					id    : order.id,
					method: 'Marked as Sent',
					input : { sent: true },
				},
			} );
		},
	}, editable && payable && order.status !== 'PAID' && !order.standing && {
		name   : t( 'common:mark-paid' ),
		details: order.standing
			? t( 'commerce:standing-automatic' )
			: t( 'commerce:use-if-offline' ),
		icon   : <CreditScoreIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( 'common:mark-paid' ),
				message: `${t( 'commerce:mark-order-as-paid' )} ${order.externalId && order.gateway?.external === 'CLOVER'
					? t( 'commerce:order-was-synced-to-clover' )
					: ''}`,
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationPaymentWriteArgs>( {
				mutation : PaymentWrite,
				variables: {
					id    : null,
					method: 'Marked as Paid',
					input : {
						gateway: order.gateway?.id || null,
						status : 'PAID',
						type   : 'PAID',
						amount : order.grandTotal,
						tip    : 0,
						client : order?.client?.id || null,
						order  : order?.id || null,
						note   : 'Marked as paid',
					},
				},
			} );
		},
	}, editable && {
		name   : order.refunded ? t( 'commerce:unmark-refunded' ) : t( 'commerce:mark-as-refunded' ),
		details: order.refunded
			? t( 'commerce:remove-refunded-status' )
			: t( 'commerce:mark-this-order-as-refunded' ),
		icon   : <MoneyOffCsredIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : order.refunded ? t( 'commerce:unmark-refunded' ) : t( 'commerce:mark-as-refunded' ),
				message: order.refunded
					? t( 'commerce:you-want-to-remove-refunded' )
					: t( 'commerce:you-want-to-mark-as-refunded' ),
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationOrderWriteArgs>( {
				mutation : OrderWrite,
				variables: {
					id    : order.id,
					method: order.refunded ? 'Removed Refunded' : 'Marked Refunded',
					input : { refunded: !order.refunded },
				},
			} );
		},
	}, editable && {
		name   : order.nonReport ? t( 'commerce:mark-as-reported' ) : t( 'commerce:mark-as-not-reported' ),
		details: order.nonReport
			? t( 'commerce:you-want-to-mark-reported', { commerceType: toLower( order.type ) } )
			: t( 'commerce:you-want-to-mark-not-reported', { commerceType: toLower( order.type ) } ),
		icon   : <AssignmentTurnedInRoundedIcon/>,
		onClick: async () => {
			const btnText = canBeUnSynced && !order.nonReport
				? `${t( 'commerce:mark-as-reported' )} & ${t( 'common:unsync' )}`
				: undefined;
			const value = await confirmDialog( {
				title             : order.nonReport
					? t( 'commerce:mark-as-reported' )
					: t( 'commerce:mark-as-not-reported' ),
				message           : order.nonReport
					? t( 'commerce:include-in-reports', { commerceType: toLower( order.type ) } )
					: t( 'commerce:exclude-from-reports', { commerceType: toLower( order.type ) } ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await markStatusAction( order, {
						nonReport: true,
					}, 'Mark reported', true );
					enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
				} : undefined,
			} );
			if ( !value ) return;
			return await markStatusAction( order, {
				nonReport: order.nonReport === true
					? null
					: true,
			}, order.nonReport ? 'Mark reported' : 'Marked not reported', true );
		},
	}, editable && {
		name   : order.closed ? t( 'commerce:unmark-closed' ) : t( 'commerce:mark-as-closed' ),
		details: order.closed
			? t( 'commerce:remove-closed-status-order' )
			: t( 'commerce:mark-this-order-as-closed' ),
		icon   : <EmailIcon/>,
		onClick: async () => {
			const btnText = canBeUnSynced && !order.closed
				? `${t( 'commerce:mark-as-closed' )} & ${t( 'common:unsync' )}`
				: undefined;
			const value = await confirmDialog( {
				title             : order.closed ? t( 'commerce:unmark-closed' ) : t( 'commerce:mark-as-closed' ),
				message           : order.closed
					? t( 'commerce:you-want-to-unmark-closed' )
					: t( 'commerce:you-want-to-mark-closed' ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await markStatusAction( order, { closed: !order.closed }, 'Marked Closed', true );
					enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
				} : undefined,
			} );
			if ( !value ) return;
			return await markStatusAction( order, { closed: !order.closed }, order.closed
				? 'Unmarked Closed'
				: 'Marked Closed' );
		},
	}, editable && {
		name   : order.voided ? t( 'commerce:unmark-voided' ) : t( 'commerce:mark-as-voided' ),
		details: order.voided
			? t( 'commerce:remove-voided-status-invoice' )
			: t( 'commerce:mark-this-invoice-as-voided' ),
		icon   : <AssignmentTurnedInRoundedIcon/>,
		onClick: async () => {
			const btnText = canBeUnSynced && !order.voided
				? `${t( 'commerce:mark-as-voided' )} & ${t( 'common:unsync' )}`
				: undefined;
			const value = await confirmDialog( {
				title             : order.voided
					? t( 'commerce:unmark-voided' )
					: t( 'commerce:mark-as-voided' ),
				message           : order.voided
					? t( 'commerce:you-want-to-unmark-voided' )
					: t( 'commerce:you-want-to-mark-as-voided' ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await markStatusAction( order, { voided: true, nonReport: true }, 'Marked Voided', true );
					enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
				} : undefined,
			} );
			if ( !value ) return;
			return await markStatusAction( order, {
				voided   : !order.voided,
				nonReport: order.voided ? null : true,
			}, order.voided
				? 'Unmarked Voided'
				: 'Marked Voided' );
		},
	}, editable && {
		name   : order.collections ? t( 'commerce:unmark-collections' ) : t( 'commerce:mark-as-collections' ),
		details: order.collections
			? t( 'commerce:remove-collections-status-order' )
			: t( 'commerce:mark-this-order-as-collections' ),
		icon   : <EmailIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : order.collections
					? t( 'commerce:unmark-collections' )
					: t( 'commerce:mark-as-collections' ),
				message: order.collections
					? t( 'commerce:you-want-to-unmark-collections' )
					: t( 'commerce:you-want-to-mark-collections' ),
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : OrderWrite,
				variables: {
					id    : order.id,
					method: order.collections ? 'Unmarked Collections' : 'Marked Collections',
					input : { collections: !order.collections },
				},
			} );
		},
	}, editable && {
		name   : order.writeOff ? t( 'commerce:unmark-write-off' ) : t( 'commerce:mark-as-write-off' ),
		details: order.writeOff
			? t( 'commerce:remove-write-off-status' )
			: t( 'commerce:mark-this-order-as-write-off' ),
		icon   : <EmailIcon/>,
		onClick: async () => {
			const btnText = canBeUnSynced && !order.writeOff
				? `${t( 'commerce:mark-as-write-off' )} & ${t( 'common:unsync' )}`
				: undefined;
			const value = await confirmDialog( {
				title             : order.writeOff
					? t( 'commerce:unmark-write-off' )
					: t( 'commerce:mark-as-write-off' ),
				message           : order.writeOff
					? t( 'commerce:you-want-to-unmark-write-off' )
					: t( 'commerce:you-want-to-mark-as-write-off' ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await markStatusAction( order, { writeOff: !order.writeOff }, 'Marked Write Off', true );
					enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
				} : undefined,
			} );
			if ( !value ) return;
			return await markStatusAction( order, { writeOff: !order.writeOff }, order.writeOff
				? 'Unmarked Write Off'
				: 'Marked Write Off' );
		},
	}, editable && {
		name   : order.pastDue ? t( 'commerce:unmark-past-due' ) : t( 'commerce:mark-as-past-due' ),
		details: order.pastDue
			? t( 'commerce:remove-past-due-status' )
			: t( 'commerce:mark-this-order-as-past-due' ),
		icon   : <EmailIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : order.pastDue ? t( 'commerce:unmark-past-due' ) : t( 'commerce:mark-as-past-due' ),
				message: order.pastDue
					? t( 'commerce:you-want-to-unmark-past-due' )
					: t( 'commerce:you-want-to-mark-as-past-due' ),
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : OrderWrite,
				variables: {
					id    : order.id,
					method: order.pastDue ? 'Unmarked Past Due' : 'Marked Past Due',
					input : { pastDue: !order.pastDue },
				},
			} );
		},
	},
	];
}
