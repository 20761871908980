import { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import { mutateGraphQL, queryGraphQL } from '@/data';
import { OrderDuplicate, OrderWrite } from '@/data/commerce/order.graphql';
import { AgreementsRead } from '@/graphql/agreement.graphql';
import { LineItemsRead } from '@/graphql/lineItem.graphql';
import SyncToClover from '@/helpers/syncToClover';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { PLATFORM_ENDPOINT } from '@/pages/api/processor/clover';
import { DeleteInfo, getDeleteBtnText } from '@/pages/dashboard/commerce/invoices/actions/moreActions';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import {
	MutationInvoiceWriteArgs,
	MutationOrderDuplicateArgs,
	MutationOrderWriteArgs,
	Order,
	QueryAgreementsReadArgs,
	QueryLineItemsReadArgs,
	Staff,
} from '@/types/schema';
import {
	CancelRounded as CancelRoundedIcon,
	ConfirmationNumber as ConfirmationNumberIcon,
	CopyAll as CopyAllIcon,
	Delete as DeleteIcon,
	Print as PrintIcon,
	Sync as SyncIcon,
	SyncProblem as SyncProblemIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { isEmpty, isString, pick } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import process from 'process';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { updateStock } from '../utils';

export function MoreActions( order: Order, single?: boolean ): ActionProps[] {
	const { t } = useTranslation();
	const confirmDialog = useConfirmDialog();
	const editable = usePermissions( permissions.orders.write );
	const { staff, user } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const router = useRouter();
	
	const deleteOrder = async ( order: Order, deleteFromClover = false ) => {
		try {
			if ( deleteFromClover ) {
				await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/deleteOrder`, { id: order.id } );
			}
			await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : OrderWrite,
				variables: {
					id    : order.id,
					method: 'Deleted Order',
					input : {
						deletedAt: new Date(),
					},
				},
			} );
			enqueueSnackbar( t( 'common:order-deleted' ) );
			
		} catch {
			enqueueSnackbar( 'Error: Order could not be deleted', { variant: 'error' } );
			
		}
		
	};
	
	const cancelOrder = async ( order: Order ) => {
		await mutateGraphQL<MutationOrderWriteArgs>( {
			mutation : OrderWrite,
			variables: {
				id    : order.id,
				method: order.status === 'CANCELLED' ? 'Marked UnCancel' : 'Marked Cancel',
				input : { cancelled: order.status !== 'CANCELLED' },
			},
		} );
	};
	
	const reverseStockAndAction = async ( order: Order,
		action: ( order: Order ) => Promise<void>,
		single?: boolean ) => {
		const isStocked = order.metadata?.stock;
		
		try {
			if ( isStocked ) {
				const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
					query    : LineItemsRead,
					variables: { options: { filter: { order: order.id } } },
				} );
				if ( isEmpty( lineItemsRead?.items ) ) {
					enqueueSnackbar( t( 'commerce:empty-stock' ), { variant: 'default' } );
				} else {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/updateInventory`,
						{ id: order.id, stock: isStocked } );
					enqueueSnackbar( t( 'common:stock-count-update-success' ), { variant: 'success' } );
				}
				
			}
			await action( order );
			if ( single ) await router.push( '/dashboard/commerce/orders' );
			
		} catch ( e ) {
			console.error( e );
			enqueueSnackbar( t( 'commerce:error-stock' ), { variant: 'default' } );
			throw e;
		}
	};
	
	return [ {
		name   : t( 'common:ticket' ),
		details: t( 'common:recommend-printer' ),
		icon   : <ConfirmationNumberIcon/>,
		props  : {
			component: PageLinkComponent,
			href     : `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/print/${order.id}/ticket`,
			target   : '_blank',
		},
	}, order.externalId && order.gateway?.external === 'CLOVER' && {
		name   : t( 'common:print-to-clover-order' ),
		icon   : <PrintIcon/>,
		onClick: () => axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/post`, {
			url   : `${PLATFORM_ENDPOINT}/${order.gateway.externalId}/print_event`,
			data  : { orderRef: { id: order.externalId } },
			config: { headers: { Authorization: `Bearer ${order.gateway.externalKey}` } },
		} ),
	}, order.externalId && order.gateway?.external === 'CLOVER' && {
		name   : t( 'common:print-to-clover-receipt' ),
		icon   : <PrintIcon/>,
		onClick: async () => {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/notification/printNotification`, {
				orderId: order.id,
			} );
		},
	}, order.externalId && {
		name   : t( 'common:sync' ),
		icon   : <SyncIcon/>,
		onClick: async () => {
			try {
				await SyncToClover( order );
				await updateStock( order, staff.company, 'manualSyncStock', enqueueSnackbar );
			} catch ( e ) {
				const cloverErrors = e?.response.data?.cloverErrors;
				if ( cloverErrors ) {
					throw isString( cloverErrors )
						? cloverErrors
						: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com';
				} else {
					enqueueSnackbar( t( 'common:sync-error' ), { variant: 'default' } );
				}
			}
		},
	}, order.externalId && ![ 'PAID', 'PARTIALLY_PAID' ].includes( order.status ) && {
		name   : t( 'common:unsync' ),
		icon   : <SyncProblemIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( 'common:unsync' ),
				message: t( 'common:confirm-unsync-invoice' ),
			} );
			if ( !value ) return;
			try {
				await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/deleteOrder`, { id: order.id } );
				
				await mutateGraphQL<MutationInvoiceWriteArgs>( {
					mutation : OrderWrite,
					variables: {
						id    : order.id,
						method: 'Unsync Order',
						input : {
							externalId   : null,
							gateway      : null,
							number       : null,
							overrideTotal: null,
						},
					},
				} );
				enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
			} catch {
				enqueueSnackbar( 'Error: The order could not be unsynced.', { variant: 'error' } );
			}
		},
	}, editable && {
		name   : order.status === 'CANCELLED' ? t( 'common:uncancel' ) : t( 'common:cancel' ),
		icon   : <CancelRoundedIcon/>,
		onClick: async () => {
			if ( order.status !== 'CANCELLED' && order.metadata?.stock ) {
				const value = await confirmDialog( {
					title             : t( 'common:cancel' ),
					message           : '',
					extraButtonText   : 'Reverse stock & cancel',
					onExtraButtonClick: async () => {
						await reverseStockAndAction( order, async ( order ) => cancelOrder( order ) );
					},
				} );
				
				if ( !value ) return;
				await cancelOrder( order );
				
			} else {
				await cancelOrder( order );
			}
		},
	}, editable && {
		name   : t( 'common:delete' ),
		icon   : <DeleteIcon/>,
		onClick: async () => {
			const btnText = getDeleteBtnText( order );
			
			const value = await confirmDialog( {
				title             : t( 'common:delete' ),
				message           : btnText ? <DeleteInfo t={t}/> : t( 'common:delete-confirmation' ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await reverseStockAndAction( order, async ( order ) => await deleteOrder( order, Boolean( order.externalId ) ), single );
				} : undefined,
			} );
			
			if ( !value ) return;
			await deleteOrder( order, false );
			if ( single ) await router.push( '/dashboard/commerce/orders' );
		},
	},
	];
	
}

export function RecurringActions( order: Order, staff: Staff, router, t ) {
	
	return order.status === 'STANDING' && order.standingActive ? [ {
		name   : t( 'commerce:add-to-history' ),
		icon   : <CopyAllIcon/>,
		onClick: async () => {
			try {
				
				const { orderDuplicate } = await mutateGraphQL<MutationOrderDuplicateArgs>( {
					mutation : OrderDuplicate,
					variables: { ids: [ order.id ] },
				} );
				
				const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
					query    : LineItemsRead,
					variables: { options: { limit: 1000, filter: { order: order.id } } },
				} );
				const lineItems = lineItemsRead?.items;
				
				const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
					query    : AgreementsRead,
					variables: { options: { limit: 1000, filter: { order: order.id } } },
				} );
				const agreements = agreementsRead?.items;
				
				await mutateGraphQL<MutationOrderWriteArgs>( {
					mutation : OrderWrite,
					variables: {
						id          : orderDuplicate.id,
						customNumber: Boolean( staff.company.metadata?.customNumber ),
						method      : 'Added to History',
						input       : {
							type          : orderDuplicate.type,
							metadata      : {
								sendReminder      : staff.company.metadata?.dueReminder,
								cardFee           : false,
								cashDiscount      : false,
								enableCashDiscount: staff.company.metadata?.cashDiscount > 0,
								cardInfo          : {},
								mergedTo          : null,
								qbSyncToken       : null,
								qbClientId        : null,
								stock             : false,
								paymentByInfo     : null,
								paymentSignatures : null,
								paymentNote       : null,
								sentDates         : null,
								reminderDates     : null,
								signatureLine     : staff.company.metadata?.signatureLine,
								mergedSignatures  : null,
								originalCommerce  : order.id,
							},
							standingData  : null,
							standingActive: null,
							standingDate  : null,
							lineItems     : lineItems?.map( ( lineItem ) => ( {
								...pick( lineItem, [
									'name',
									'price',
									'image',
									'unit',
									'quantity',
									'tax',
									'metadata',
									'externalId',
								] ),
								description   : `${lineItem.description || ''}`,
								modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
								prices        : lineItem.prices?.map( ( price ) =>
									pick( price, [
										'name',
										'isPercent',
										'value',
										'quantity',
										'metadata',
										'externalId',
									] ) ),
								uom           : lineItem.uom?.id || null,
								item          : lineItem.item?.id || null,
								category      : lineItem.category?.id || null,
							} ) ),
							agreements    : agreements?.map( ( agreement ) => ( {
								...pick( agreement, [
									'title',
									'body',
									'requireSignature',
									'expiration',
								] ),
								company: agreement.company.id || staff.company.id,
							} ) ),
							prices        : !isEmpty( orderDuplicate.prices )
								? orderDuplicate.prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
									.map( ( { id } ) => ( { id } ) )
								: [],
						},
					},
				} );
				
				await router.push( `/dashboard/commerce/orders/${orderDuplicate.id}/edit` );
			} catch ( e ) {
				console.error( e );
				throw e;
			}
		},
	} ] : [];
}

